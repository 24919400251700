import React, { Component } from "react";
import window from 'global';
import '../styles/share.scss';
import { graphql, Link } from "gatsby";
import Helmet from "react-helmet";
import Paper from '@material-ui/core/Paper';
import { css } from "@emotion/core";
import Layout from "../components/layout.js";
import SEO from "../components/seo";
import Img from "gatsby-image";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { AuthUserContext } from '../components/Session';
//import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PortfolioProduct from "../components/portfolioproduct";
import MasterTetherList from "../components/mastertetherlist";
import Icon from '@material-ui/core/Icon';
import Navlinks from '../components/nav';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
// import Accordion from '@material-ui/core/Accordion';
// import AccordionSummary from '@material-ui/core/AccordionSummary';
// import AccordionDetails from '@material-ui/core/AccordionDetails';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; 
import ImagePicker from 'react-image-picker';
import 'react-image-picker/dist/index.css'
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import { Offline, Online } from 'react-detect-offline';
//import Video from "../components/video"
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const images=[]
const carouselSlidesData = [];

const mineralized = [];
const basic = [];
const schloride = [];
const pchloride = [];

var webURL = "https://saltsmartapp.com";

function portfolioFormOpen(e) {
  var product = e.currentTarget.getAttribute('pbutton');
  var pForm = document.getElementById(product);
  pForm.classList.add('active');
  //document.getElementById('pForm').reset();
}

class CarouselLeftArrow extends Component {
  render() {
    return (
      <button className="carousel__arrow carousel__arrow--left" onClick={this.props.onClick} ><span className="fa fa-2x fa-angle-left"/></button>
    );
  }
}

class CarouselRightArrow extends Component {
  render() {
    return (
      <button className="carousel__arrow carousel__arrow--right" onClick={this.props.onClick} ><span className="fa fa-2x fa-angle-right"/></button>
    );
  }
}

class CarouselIndicator extends Component {
  render() {
    return (
      <li>
        <a
          className={
            this.props.index == this.props.activeIndex
              ? "carousel__indicator carousel__indicator--active"
              : "carousel__indicator"
          }
          onClick={this.props.onClick}
        />
      </li>
    );
  }
}

class CarouselSlide extends Component {
  render() {
    return (
      <li
        className={
          this.props.index == this.props.activeIndex
            ? "carousel__slide carousel__slide--active"
            : "carousel__slide"
        }
      >
        <div className="video">
          <div className="videoInfo">
            <h3 className='videoTitle' dangerouslySetInnerHTML={{ __html: this.props.slide.title}}/>
            <div className='videoDescription' dangerouslySetInnerHTML={{ __html: this.props.slide.description}}/>
          </div>
          <video 
            className="galleryVideo"
            src={this.props.slide.url}
            title={this.props.slide.title}
            poster={this.props.slide.poster}
            controls={true}
            playsinline
            webkit-playsinline
            crossOrigin="anonymous"
            type="video/mp4"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            preload="auto"
            >
            </video>
        </div>
        <Online>
          <Link>
            <button value={'shareDialogV'+this.props.index} id={'deviceShareV'+this.props.index} type="button" className="icon-share" title={'Share '+this.props.slide.title} data-file={this.props.slide.siteURL+this.props.slide.url} onClick={openShareDialog}/>            
          </Link>        
          <div id={'shareDialogV'+this.props.index} className="share-dialog">
            <button value={'shareDialogV'+this.props.index} onClick={closeShareDialog} class="boxClose"></button>
            <div class="inner">
              <header>
                <h3 className="dialog-title">Share {this.props.slide.title}</h3>
              </header>
              <button value={'shareDialogV'+this.props.index} className="close-button" onClick={closeShareDialog}></button>
              <div className="targets">
                <a className="button" href={'mailto:?body='+this.props.slide.siteURL+this.props.slide.url}>
                  <svg>
                    <use href="#email"></use>
                  </svg>
                  <span>Email</span>       
                </a>
              </div>
            </div>
          </div>
        </Online>
        <Offline>
          <button className="icon-share disabled" />
        </Offline>
        
      </li>
    );
  }
}

// Carousel wrapper component
class Carousel extends Component {
  constructor(props) {
    super(props);

    this.goToSlide = this.goToSlide.bind(this);
    this.goToPrevSlide = this.goToPrevSlide.bind(this);
    this.goToNextSlide = this.goToNextSlide.bind(this);

    this.state = {
      activeIndex: 0
    };
  }

  goToSlide(index) {
    this.setState({
      activeIndex: index
    });
    const galleryVideo = document.getElementsByClassName('galleryVideo');
    galleryVideo[index].pause()
  }

  goToPrevSlide(e) {
    e.preventDefault();
    
    let index = this.state.activeIndex;
    let { slides } = this.props;
    let slidesLength = slides.length;
    const galleryVideo = document.getElementsByClassName('galleryVideo');
    galleryVideo[index].pause()
    if (index < 1) {
      index = slidesLength;
    }
    
    --index;

    this.setState({
      activeIndex: index,
      
    });
    
  }

  goToNextSlide(e) {
    e.preventDefault();

    let index = this.state.activeIndex;
    let { slides } = this.props;
    let slidesLength = slides.length - 1;
    const galleryVideo = document.getElementsByClassName('galleryVideo');
    galleryVideo[index].pause()
    if (index === slidesLength) {
      index = -1;
    }
    
    ++index;

    this.setState({
      activeIndex: index
    });
    
  }

  render() {
    return (
      <div className={"carousel fCol12 c"+carouselSlidesData.length}>
        <CarouselLeftArrow onClick={e => this.goToPrevSlide(e)} />

        <ul className="carousel__slides">
          {this.props.slides.map((slide, index) =>
            <CarouselSlide
              key={index}
              index={index}
              activeIndex={this.state.activeIndex}
              slide={slide}
            />
            
          )}
        </ul>

        <CarouselRightArrow onClick={e => this.goToNextSlide(e)} />

        <ul className="carousel__indicators">
          {this.props.slides.map((slide, index) =>
            <CarouselIndicator
              key={index}
              index={index}
              activeIndex={this.state.activeIndex}
              isActive={this.state.activeIndex==index} 
              onClick={e => this.goToSlide(index)}
            />
          )}
        </ul>
      </div>
    );
  }
}


export class LightboxGallery extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }
 
  render() {
    const { photoIndex, isOpen } = this.state;
    const { pID } = this.props;
    return (
      <div>
        {images.map((image, index) => (
          <React.Fragment>
            <div id={'lbItem-'+pID+index} class="galleryItem"><img id={'lbThumbnail-'+pID+index} class="lightboxThumbnail" src={ image.lbImage }  onClick={() => this.setState({ isOpen: true, photoIndex: index })}/></div>
          </React.Fragment>
        ))}
        {isOpen && (
          <Lightbox
            imageCaption={images[photoIndex].lbCaption}
            mainSrc={images[photoIndex].lbImage}
            nextSrc={images[(photoIndex + 1) % images.length].lbImage}
            prevSrc={images[(photoIndex + images.length - 1) % images.length].lbImage}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </div>
    );
  }
}

export class SelectGallery extends Component {
  constructor(props) {
    super(props)
    this.state = {
      image: null,
      images: []
    }
  }

  onPickImages(images) {
    this.setState({images})
  }
  removeCloseSelectImg = () => {
    
    //this.state.images.length = 0;
    //this.setState({ images: [] });
    this.setState({ image:null });
    this.setState({ images: [] });
    var element = document.getElementById("selectWrapperImage");
    var imgLite = document.getElementById("lightboxWrapper");
    var imgShareIcn = document.getElementById("shareGalIcon");
    if (element.classList.contains('active-selects')) {
      element.classList.remove('active-selects');
      element.classList.add('inactive-selects');

    } else {
      element.classList.add('active-selects');
      element.classList.remove('inactive-selects');

    }
    if (imgLite.classList.contains('active-selects')) {
      imgLite.classList.remove('active-selects');
      imgLite.classList.add('inactive-selects');
    } else {
      imgLite.classList.add('active-selects');
      imgLite.classList.remove('inactive-selects');
    }
    if (imgShareIcn.classList.contains('active-shareICN')) {
      imgShareIcn.classList.remove('active-shareICN');
      imgShareIcn.classList.add('inactive-shareICN');    
    } else {
      imgShareIcn.classList.add('active-shareICN');
      imgShareIcn.classList.remove('inactive-shareICN');
    }
    var imgPickerResp = document.getElementsByClassName("responsive");
    for(var i = 0, length = imgPickerResp.length; i < length; i++) {   
      if( imgPickerResp[i].textContent == ''){
        imgPickerResp[i].classList.remove('selected');
        //imgTest[i].style.display = 'none';
      }     
    }
    var imgPickThumb = document.getElementsByClassName("thumbnail");
    for(var i = 0, length = imgPickThumb.length; i < length; i++) {   
      if( imgPickThumb[i].textContent == ''){
        imgPickThumb[i].classList.remove('selected');
        //imgTest[i].style.display = 'none';
      }     
    }
    
  }
  render() {
    return (
      <div>
        <React.Fragment>
          <span id="shareGalIcon" className="icons inactive-shareICN">
            <div class="galleryShare">
              <button value={'shareDialogGal'} id={'deviceShareGal'} type="button" data-html="true"  title={'Share '} data-file={this.state.images.map((item) => webURL+item.src+`\n`+`\n`)} onClick={openShareDialogSelectImages} css={css`
                white-space: pre-line!important;white-space: pre-wrap!important;
              `}>
                Share Selected <span className="icon-share"> </span>
              </button>            
              <span className="shareSuccess"></span>
              <button className="shareCancel" onClick={this.removeCloseSelectImg}>Cancel</button>
            </div>
          </span>  
        </React.Fragment>

        <React.Fragment>
          <ImagePicker 
            images={images.map((image, i) => ({src: image.lbImage, value: i}))}
            onPick={this.onPickImages.bind(this)}
            multiple
          />
          {/*<textarea rows="4" cols="100" value={this.state.images && JSON.stringify(this.state.images)} disabled/>*/}

          <div id={'shareDialogGal'} className="share-dialog">
            <button value={'shareDialogGal'} onClick={closeShareDialogSelectImages} className="boxClose"></button>
            <div className="inner">
              <header>
                <h3 className="dialog-title">Share Images</h3>
              </header>
              <button value={'shareDialogGal'} className="close-button" onClick={closeShareDialogSelectImages}></button>
              <div className="targets">
                <a className="button" href={('mailto:?subject=Check out these images&body=Check out these images:%0D%0A%0D%0A'+this.state.images.map((item) => webURL+item.src+'%0D%0A')).replace(/,/g, '')}>
                  <svg>
                    <use href="#email"></use>
                  </svg>
                  <span>Email</span>
                </a>
                
                <div class="hide">
                  <ul>
                  {this.state.images.map((item, value) => (
                    <React.Fragment>
                    <li className={'product-carried-'+value}>{webURL+item.src}</li>                   
                    </React.Fragment>
                  ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      </div>
    );
  }
}

 
function tabNavigation(e) {
  var clickedTab = e.currentTarget.getAttribute('data-tab');
  var clickedContent = e.currentTarget.getAttribute('data-content');
  var clickedMobile = e.currentTarget.getAttribute('data-mob-tab');
  var currentTab = document.getElementById(clickedTab);
  var currentContent = document.getElementById(clickedContent);

  var tabAdd = document.getElementById('addTab');
  var cAdd = document.getElementById('additional-content');

  if (!currentTab.classList.contains('active')) {
      var homeContent = document.getElementById('content-home');
      if (clickedContent != 'additional-content') {
        if (clickedContent !== 'content-home') {
          homeContent.classList.remove('active');
        }else {
          homeContent.classList.add('active');
        }
      
      
        var tabContents = document.getElementsByClassName('secondaryTab');
        for (var i = 0; i < tabContents.length; i++) {
            if (tabContents[i].id !== clickedContent) {
                tabContents[i].classList.remove('active');
            } else {
                tabContents[i].classList.add('active');
            }
        }

        var tabs = document.getElementsByClassName('tab');
        for (var i = 0; i < tabs.length; i++) {
            if (tabs[i].id !== clickedTab) {
                tabs[i].classList.remove('active');
            } else {
                tabs[i].classList.add('active');
            }
        }
        cAdd.classList.remove('expand');
        var pForm = document.getElementsByClassName("portfolioPopup");
        for (var ipf = 0; ipf < pForm.length; ipf++) {
          pForm[ipf].classList.remove('active');
        }
      }
      

      if (clickedMobile == 'true') {
          tabAdd.classList.add('active')
      } else if (clickedTab !== 'tabAdd') {
          tabAdd.classList.remove('active')
      }
      const galleryVideo = document.getElementsByClassName('galleryVideo');
      Array.prototype.forEach.call(galleryVideo, function(videoP) {
      videoP.pause();
      });
      window.scrollTo(0, 0);
      var tabsContainer = document.getElementById("tabsContainer");
      tabsContainer.scrollTo(0, 0);
  }
  if (clickedContent == 'additional-content') {
    if (cAdd.classList.contains('expand')) {
        cAdd.classList.remove('expand');
    } else {
        cAdd.classList.add('expand');
    }
  }
}

function closeMenu() {
  var element = document.getElementById("additional-content");
  if (element.classList.contains('expand')) {
    element.classList.remove('expand');
  } else {
    
  }
}

function selectImageCollapse() {
  var element = document.getElementById("selectWrapperImage");
  var imgLite = document.getElementById("lightboxWrapper");
  var imgShareIcn = document.getElementById("shareGalIcon");
  if (element.classList.contains('active-selects')) {
    element.classList.remove('active-selects');
    element.classList.add('inactive-selects');

  } else {
    element.classList.add('active-selects');
    element.classList.remove('inactive-selects');

  }
  if (imgLite.classList.contains('active-selects')) {
    imgLite.classList.remove('active-selects');
    imgLite.classList.add('inactive-selects');
  } else {
    imgLite.classList.add('active-selects');
    imgLite.classList.remove('inactive-selects');
  }
  if (imgShareIcn.classList.contains('active-shareICN')) {
    imgShareIcn.classList.remove('active-shareICN');
    imgShareIcn.classList.add('inactive-shareICN');    
  } else {
    imgShareIcn.classList.add('active-shareICN');
    imgShareIcn.classList.remove('inactive-shareICN');
  }
  var imgPickerResp = document.getElementsByClassName("responsive");
  for(var i = 0, length = imgPickerResp.length; i < length; i++) {   
    if( imgPickerResp[i].textContent == ''){
      imgPickerResp[i].classList.remove('selected');
      //imgTest[i].style.display = 'none';
    }     
  }
  var imgPickThumb = document.getElementsByClassName("thumbnail");
  for(var i = 0, length = imgPickThumb.length; i < length; i++) {   
    if( imgPickThumb[i].textContent == ''){
      imgPickThumb[i].classList.remove('selected');
      //imgTest[i].style.display = 'none';
    }     
  }
  //var productList = document.querySelector('#selectWrapperImage .image_picker .responsive.selected').children;
  //console.log('thumbs: ', productList);
}

function pdfOpen(e) {
  const pdfViewer = document.getElementById(e.currentTarget.value);
  if (pdfViewer.classList.contains('active')) {
    pdfViewer.classList.remove('active');
  } else {
    /*const pdfViewers = document.getElementsByClassName('pdfViewer');
    Array.prototype.forEach.call(pdfViewers, function(pdfAll) {
      pdfAll.classList.remove('active');
    });*/
    pdfViewer.classList.add('active');
  }
}
function pdfClose(e) {
  const pdfViewer = document.getElementById(e.currentTarget.value);
  if (pdfViewer.classList.contains('active')) {
    pdfViewer.classList.remove('active');
  }
}

class Pdfviewer extends Component {
  state = { numPages: null, pageNumber: 1, scaleControl: .75};

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  goToPrevPage = () =>
    this.setState(state => ({ pageNumber: state.pageNumber - 1 }));
  goToNextPage = () =>
    this.setState(state => ({ pageNumber: state.pageNumber + 1 }));
  zoomIn = () =>
    this.setState(state => ({ scaleControl: state.scaleControl + .5 }));
  zoomOut = () =>
    this.setState(state => ({ scaleControl: state.scaleControl - .5 }));
  zoomReset = () =>
    this.setState(state => ({ scaleControl: 1 }));
  render() {
    const { pageNumber, numPages, scaleControl } = this.state;
    var intFrameHeight = window.innerHeight;
    var intFrameWidth = window.innerWidth;
    if (intFrameWidth > intFrameHeight) {
      intFrameWidth = intFrameHeight / 1.2 - 75;
    }
    if (intFrameHeight > intFrameWidth) {
      intFrameHeight = intFrameWidth / 1.1 - 75;
      intFrameWidth = intFrameWidth / 1.1 - 75;
    }
    return (
      <div id={this.props.id} className={this.props.className}>
        
        <button value={this.props.id} class="pdfClose" onClick={pdfClose}></button>
        <button value={this.props.id} class="pdfCloseX" onClick={pdfClose}></button>
        <div class="pdfBox">
          <Document
            file={this.props.file}
            onLoadSuccess={this.onDocumentLoadSuccess}
            height={intFrameHeight}
          >
            <Page pageNumber={pageNumber} scale={scaleControl} width={intFrameWidth}/>
          </Document>
        </div>
        <nav>
          {pageNumber > 1 &&
            <button class="prevArrow" onClick={this.goToPrevPage}></button>
          }
          {pageNumber <= 1 &&
            <button class="prevArrow disabled"></button>
          }
          <div className="pageNumbers">{pageNumber}/{numPages}</div>
          {pageNumber < numPages &&
            <button class="nextArrow" onClick={this.goToNextPage}></button>
          }
          {pageNumber >= numPages &&
            <button class="disabled nextArrow"></button>
          }
          {scaleControl > .5 &&
            <button class="zoomIcon" onClick={this.zoomOut}><Icon>zoom_out</Icon></button>
          }
          {scaleControl <= .5 &&
            <button class="zoomIcon disabled"><Icon>zoom_out</Icon></button>
          }
          {scaleControl <= 3 &&
            <button class="zoomIcon" onClick={this.zoomIn}><Icon>zoom_in</Icon></button>
          }
          {scaleControl > 3 &&
            <button class="zoomIcon disabled"><Icon>zoom_in</Icon></button>
          }
        </nav>
      </div>
    );
  }
}

function openShareDialog(e) {
  var shareDialog = document.getElementById(e.currentTarget.value);
  var shareTitle = e.currentTarget.title;
  var shareFile = e.currentTarget.getAttribute('data-file');
  
  //const resultPara = document.querySelector('.shareSuccess');
  e.preventDefault();
  //shareDialog.classList.add('is-open');
  console.log('The link was clicked.');
  if (navigator.share) {
      navigator.share({
        title: shareTitle,
        url: shareFile
      }).then(() => {
        //resultPara.textContent = 'Shared successfully'
        console.log('Thanks for sharing!');
      })
      .catch(console.error);
    } else {
      shareDialog.classList.add('is-open');
    }
}

function closeShareDialog(e) {
  var shareDialog = document.getElementById(e.currentTarget.value);
  e.preventDefault();
  shareDialog.classList.remove('is-open');
  console.log('The link was clicked.');
}

function openShareDialogSelectImages(e) {
  var shareDialog = document.getElementById(e.currentTarget.value);
  var shareTitle = e.currentTarget.title;
  var shareFile = e.currentTarget.getAttribute('data-file');
  
  //const resultPara = document.querySelector('.shareSuccess');
  e.preventDefault();
  //shareDialog.classList.add('is-open');
  console.log('The link was clicked.');
  if (navigator.share) {
      navigator.share({
        title: shareTitle,
        text: shareFile
      }).then(() => {
        //resultPara.textContent = 'Shared successfully'
        console.log('Thanks for sharing!');
      })
      .catch(console.error);
    } else {
      shareDialog.classList.add('is-open');
    }
}

function closeShareDialogSelectImages(e) {
  var shareDialog = document.getElementById(e.currentTarget.value);
  e.preventDefault();
  shareDialog.classList.remove('is-open');
  console.log('The link was clicked.');
}

function saveAsPDF(content) {
  // fake server request, getting the file url as response
  var dloFile = content.currentTarget.getAttribute('data-file');
  var dloServer = 'http://files.sales-app.cmps.emfluence.com/';
  if (window.matchMedia('(display-mode: standalone)').matches)  {
    var windowReference = window.open();
    windowReference.location = dloServer+dloFile;
  }
  else {
    window.location.href = dloFile;
  }
  
  /*
  setTimeout(() => {
    const response = {
      file: dloFile,
    };
    // server sent the url to the file!
    // now, let's download:
    //window.location.href = response.file;
    // you could also do:
    windowReference.locaton = response.file;
  }, 1);
  */
}




const stuff = props => {
  const { pageContext } = props
  //const { searchData } = pageContext
  //const { allPages, allFiles, options } = searchData
  //const allSearch = allPages.concat(allFiles);
  return(pageContext)
}

const BrandTemplate = ({ data, pageContext }) => {
  const tetherParentLink = pageContext.parentLink;
  const tetherParentText = pageContext.parentText;
  const tetherParentCategory = pageContext.parentCategory;
  const tetherID = pageContext.tether_id;
  return(
  <Layout>
    <SEO
      title={data.wordpressPage.yoast_meta.yoast_wpseo_title}
      description={data.wordpressPage.yoast_meta.yoast_wpseo_metadesc}
    />
    
    <AuthUserContext.Consumer>
          {authUser =>
              authUser ? (
                <Helmet>
                  <meta name="format-detection" content="telephone=no" />
                  <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
                  <body className={"brand-page imageCount"+images.length} />
                </Helmet>
              ) : (
                <Helmet>
                  <meta name="format-detection" content="telephone=no" />
                  <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
                  <body className={"brand-page loggedOut imageCount"+images.length} />
                </Helmet>
              )
          }
      </AuthUserContext.Consumer>
    <header className="mainHead flexContainer faCenter fjCenter" css={css`
        background: ${data.wordpressPage.acf.brand_color};
    `}>      
        <div className="fCol12 menuCtrl">
          {tetherParentLink === null &&
            <Link className="headerTitle" to={`/${data.wordpressPage.parent_element.parent_element.slug}/${data.wordpressPage.parent_element.slug}/`}>
              <span className="arrowbk"><Icon>arrow_back</Icon></span><span className="mobileShow">Back</span><span className="mobileHide" dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.parent_brand+" "+data.wordpressPage.parent_element.title+" Brands"}}/>
            </Link>
          }
          {tetherParentLink != null &&
            <Link className="headerTitle" to={tetherParentLink}>
              <span className="arrowbk"><Icon>arrow_back</Icon></span><span className="mobileShow">Back</span><span className="mobileHide" dangerouslySetInnerHTML={{ __html: tetherParentText}}/>
            </Link>
          }
          {data.wordpressPage.acf.parent_brand === 'Canada' &&
            <React.Fragment>
              <Link className="headerTitle" to={`/${data.wordpressPage.parent_element.parent_element.slug}/${data.wordpressPage.parent_element.slug}/`}>
                <span className="arrowbk"><Icon>arrow_back</Icon></span><span className="mobileShow">Back</span><span className="mobileHide" dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.parent_brand+" "+data.wordpressPage.parent_element.title+" Brands"}}/>
              </Link>
            </React.Fragment>                  
          }






          {/* Nav Side */}
          <Navlinks />
        </div>
    </header>
    <div id="tabsContainer" className="tabsContainer brand" css={css`margin-top:5vh`}>
      <section id="content-home" className="content-home active">
        <div className="flexContainer hero">
          {/*
          {data.wordpressPage.acf.brand_hero != null &&
          <Img className="fCol12 heroImage"
              sizes={data.wordpressPage.acf.brand_hero.localFile.childImageSharp.sizes} 
          />
          }
          */}
          {data.wordpressPage.acf.brand_hero != null &&
            <div className="fCol12 heroImage">
                <div aria-hidden="true" css={css`width: 100%; padding-bottom: 90.7216%;`}></div>
                  <picture>
                    <img className="singleHEROcall" src={data.wordpressPage.acf.brand_hero.localFile.publicURL}  />
                  </picture>
                </div>

          }

         


          <div className="fCol12 brandLogo-b">
            <div className="maxTablet flexContainer fjLeft">
              <div class="whiteContainer fCol12 fCol5-xs fCol5-sm">
                {/*
                {data.wordpressPage.acf.brand_logo != null &&
                <Img
                  sizes={data.wordpressPage.acf.brand_logo.localFile.childImageSharp.sizes}
                />
                }
                */}
                {data.wordpressPage.acf.brand_logo != null &&
                  <div className=" gatsby-image-wrapper">
                    <div aria-hidden="true" css={css`width: 100%; padding-bottom: 100%;`}></div>
                    <picture>
                      <img src={data.wordpressPage.acf.brand_logo.localFile.publicURL} />
                    </picture>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="flexContainer faStretch maxTablet brandContentContainer">  
          <div className="fCol12 fCol5-xs fCol5-md talkingPoints">
            <ul>
              {data.wordpressPage.acf.brand_points_rp != null && data.wordpressPage.acf.brand_points_rp.map((point, i) => (
                <React.Fragment>
                  <li key={i} css={css`
                    color: ${data.wordpressPage.acf.brand_color};
                  `}
                  dangerouslySetInnerHTML={{ __html: point.brand_point }}
                  />
                </React.Fragment>
              ))}
            </ul>
          </div>
          {data.wordpressPage.acf.brand_family_toggle != true &&
            <React.Fragment>
              {data.wordpressPage.acf.brand_footnote != null &&
                <div className="fCol12 fCol7-xs fCol7-md brandCopy" dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.brand_copy+'<br/><a class="brandLink" href="'+data.wordpressPage.acf.brand_site_link+'" target="_blank">'+data.wordpressPage.acf.brand_site_text+'</a>'+'<div class="footnote">'+data.wordpressPage.acf.brand_footnote+'</div>' }} />
              }
            </React.Fragment>
          }
          {data.wordpressPage.acf.brand_family_toggle === true &&
            <React.Fragment>
                <div className="fCol12 fCol7-xs fCol7-md brandCopy" dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.brand_copy+'<br/><a class="brandLink" href="'+data.wordpressPage.acf.brand_site_link+'" target="_blank">'+data.wordpressPage.acf.brand_site_text+'</a>'}} />
            </React.Fragment>
          }
          {data.wordpressPage.acf.brand_footnote === null &&
            <div className="fCol12 fCol7-xs fCol7-md brandCopy" dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.brand_copy+'<br/><a class="brandLink" href="'+data.wordpressPage.acf.brand_site_link+'" target="_blank">'+data.wordpressPage.acf.brand_site_text+'</a>'}} />
          }
          
          {data.wordpressPage.acf.brand_family_toggle === true &&
            <React.Fragment>
              {data.wordpressPage.acf.brand_family_img != null &&
                <Img className="fCol12 fCol7-xs brandFamilyToggleImage"
                    sizes={data.wordpressPage.acf.brand_family_img.localFile.childImageSharp.sizes}
                />
              }
              {data.wordpressPage.acf.brand_footnote != null &&
                <div className="fCol12 fCol5-xs fCol4-sm brandFamilyToggleFootnote" dangerouslySetInnerHTML={{ __html: '<div class="footnote">'+data.wordpressPage.acf.brand_footnote+'</div>' }} />
              }
            </React.Fragment>
          }
        </div>
        <div className="flexContainer faStretch maxTablet">
          <div className="fCol12">
          {data.wordpressPage.acf.brand_family_toggle != true &&
            <React.Fragment>
              {data.wordpressPage.acf.brand_family_img != null &&
                <Img className="fCol12"
                    sizes={data.wordpressPage.acf.brand_family_img.localFile.childImageSharp.sizes}
                  />
              }
            </React.Fragment>
          }
          </div>
        </div>
      </section>
      {data.wordpressPage.acf.brand_img_galy !=null &&
      <section id="content-gallery" className="content-gallery secondaryTab">
        <div className="fCol12 brandName" css={css` color: ${data.wordpressPage.acf.brand_color}; `}>
            {data.wordpressPage.acf.brand_img_tab_or.or_check !=true &&
            <div className="subHeading">
              <h1 dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.brand_name+' Brand Imagery'}} />
              <p className="fCol12 portalText" dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.brand_img_galy_portal}} />
            </div>
            }
            {data.wordpressPage.acf.brand_img_tab_or.or_check ==true &&
              <div className="subHeading">
                {data.wordpressPage.acf.brand_img_tab_or.or_front ==true &&
                  <h1 dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.brand_name+' '+data.wordpressPage.acf.brand_img_tab_or.or_headline}} />
                }
                {data.wordpressPage.acf.brand_img_tab_or.or_front !=true &&
                  <h1 dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.brand_img_tab_or.or_headline}} />
                }
                <p className="fCol12 portalText" dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.brand_img_galy_portal}} />
              </div>
            }
        </div>
        <div className="fCol12 maxTablet flexContainer fjLeft">
          <div className="hide">
            {images.length = 0}
          </div>
          {data.wordpressPage.acf.brand_img_galy !=null && data.wordpressPage.acf.brand_img_galy.map(galy => (
            <React.Fragment>
              {galy.localFile != null &&
              <div className="hide">
                {images.push({"lbImage": galy.localFile.publicURL,"lbCaption": galy.caption.replace(/(<([^>]+)>)/ig,"").replace("&#8217;", "'")})}
                
              </div>
              }
              
            </React.Fragment>  
          ))}
          <div id="lightboxWrapper" className="lightboxWrapper fCol12 active-selects">
            
            <div class="galleryShare single">
              <Online>
                <a className="toggleShareSelect" onClick={selectImageCollapse}>SELECT IMAGE(S) TO SHARE</a>
              </Online>
              <Offline>
                <a className="toggleShareSelect disabledLink">SELECT IMAGE(S) TO SHARE</a>
              </Offline>
            </div>
            
            
              
            
            <LightboxGallery pID={data.wordpressPage.slug}/>
          </div>
          <div id="selectWrapperImage" className="fCol12 selectWrapperImage inactive-selects" >
            <SelectGallery />
          </div>


        </div>
      </section>
      }
      {data.wordpressPage.acf.brand_img_galy == null && <section id="content-gallery" className="content-gallery secondaryTab"></section>}
      {data.wordpressPage.acf.brand_videos !=null &&
      <section id="content-videos" className="content-videos secondaryTab">
        <div className="fCol12 brandName" css={css` color: ${data.wordpressPage.acf.brand_color}; `}>
          {data.wordpressPage.acf.brand_video_tab_or.or_check !=true &&
            <div className="subHeading">
              <h1 dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.brand_name+' Brand Videos'}} />
            </div>
          }
          {data.wordpressPage.acf.brand_video_tab_or.or_check ==true &&
            <div className="subHeading">
              {data.wordpressPage.acf.brand_video_tab_or.or_front ==true &&
                <h1 dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.brand_name+' '+data.wordpressPage.acf.brand_video_tab_or.or_headline}} />
              }
              {data.wordpressPage.acf.brand_video_tab_or.or_front !=true &&
                <h1 dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.brand_video_tab_or.or_headline}} />
              }
            </div>
          }
        </div>
        <div className="fCol12 maxTablet flexContainer fjLeft">
          <div className="hide">
              {carouselSlidesData.length = 0}
          </div>
          {data.wordpressPage.acf.brand_videos !=null && data.wordpressPage.acf.brand_videos.map(video => (
            <React.Fragment>  
              <div className="hide">
                <React.Fragment>  
                  {carouselSlidesData.push({"title": video.video_title,"description": video.video_description, "url": video.video_select_path, "poster": video.video_poster.localFile.publicURL, "siteURL": data.site.siteMetadata.siteUrl})}
                </React.Fragment>  
                {/*
                {video.video_select.localFile && (
                  <React.Fragment>  
                  {carouselSlidesData.push({"title": video.video_title,"description": video.video_description, "url": video.video_select.localFile.localURL, "poster": video.video_poster.localFile.publicURL, "siteURL": data.site.siteMetadata.siteUrl})}
                  </React.Fragment>  
                )}
                */}
              </div>
            </React.Fragment>  
          ))}
          <Carousel className={carouselSlidesData.length} slides={carouselSlidesData} />
        </div>
      </section>
      }
      {data.wordpressPage.acf.brand_videos == null && <section id="content-videos" className="content-videos secondaryTab"></section>}
      {data.wordpressPage.acf.brand_add_collateral != null &&
      <section id="content-collateral" className="content-collateral secondaryTab">
        <div className="fCol12 brandName" css={css` color: ${data.wordpressPage.acf.brand_color}; `}>
          {data.wordpressPage.acf.brand_collateral_tab_or.or_check !=true &&
            <div className="subHeading">
              <h1 dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.brand_name+' Brand Collateral'}} />
            </div>
          }
          {data.wordpressPage.acf.brand_collateral_tab_or.or_check ==true &&
            <div className="subHeading">
              {data.wordpressPage.acf.brand_collateral_tab_or.or_front ==true &&
                <h1 dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.brand_name+' '+data.wordpressPage.acf.brand_collateral_tab_or.or_headline}} />
              }
              {data.wordpressPage.acf.brand_collateral_tab_or.or_front !=true &&
                <h1 dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.brand_collateral_tab_or.or_headline}} />
              }
            </div>
          }
        </div>
        {data.wordpressPage.acf.brand_add_collateral != null && data.wordpressPage.acf.brand_add_collateral.map((collateral, index) => (
          <div>
            <React.Fragment>
              <div className="grayBorderBottom">
                <div className="flexContainer maxTablet">
                  <div className="fCol12 flexContainer faCenter">
                    <div className="dIcon">
                    {collateral.brand_upload_collateral != null &&
                      <button value={'pdfViewer'+index} /*cUrl={collateral.prod_upload_collateral.url.localFile.publicURL}*/ onClick={ pdfOpen } title={'View '+collateral.brand_collateral_name+collateral.brand_collateral_name_line}>
                        {collateral.brand_upload_collateral != null &&                         
                          <Img 
                          className=""
                          sizes={collateral.brand_collateral_thumb.localFile.childImageSharp.sizes}
                        />
                        }
                            VIEW        
                      </button>
                    }
                    </div>
                    <div className='dName' dangerouslySetInnerHTML={{ __html: collateral.brand_collateral_name+'<br/>'+collateral.brand_collateral_name_line}}></div>
                    <div className='dButtons'>
                      {collateral.brand_collateral_order !== "" &&
                        <Link target="_blank" to={collateral.brand_collateral_order}>
                          <button className="grayButton">Order</button>              
                        </Link>
                      }
                      <Online>
                      {collateral.brand_upload_collateral.url.localFile && (
                      <Link>
                        <button value={'shareDialog'+index} id={'deviceShare'+index} type="button" className="grayButton" title={'Share '+collateral.brand_collateral_name+collateral.brand_collateral_name_line} data-file={data.site.siteMetadata.siteUrl+collateral.brand_upload_collateral.url.localFile.localURL} onClick={openShareDialog}>Share</button>            
                      </Link>
                      )}
                      </Online>
                      <Offline>
                        <a className="offlinebtns">
                          <button type="button" className="grayButton disabled shareD">Share</button>
                        </a>
                      </Offline>
                      
                      <Online>
                        {collateral.brand_collateral_zip != null &&
                          collateral.brand_collateral_zip.url.localFile && (
                            <a onClick={saveAsPDF} data-file={collateral.brand_collateral_zip.url.localFile.localURL}  title={'Download '+collateral.brand_collateral_name+collateral.brand_collateral_name_line}><button  className="grayButton">Download</button></a>
                          )
                        }
                      </Online>
                      <Offline>
                        <a className="offlinebtns">
                          <button type="button" className="grayButton disabled">Download</button>
                        </a>
                      </Offline>                      
                    </div>
                  </div>
                </div>
              </div>
              {collateral.brand_upload_collateral.url.localFile && (
                <Pdfviewer id={'pdfViewer'+index} className="pdfViewer" file={collateral.brand_upload_collateral.url.localFile.localURL}/>
              )}
              <div id={'shareDialog'+index} className="share-dialog">
                <button value={'shareDialog'+index} onClick={closeShareDialog} class="boxClose"></button>
                <div class="inner">
                  <header>
                    <h3 className="dialog-title">Share {collateral.brand_collateral_name}</h3>
                  </header>
                  <button value={'shareDialog'+index} className="close-button" onClick={closeShareDialog}></button>
                  <div className="targets">
                    {collateral.brand_upload_collateral.url.localFile && (
                      <a className="button" href={'mailto:?body='+data.site.siteMetadata.siteUrl+collateral.brand_upload_collateral.url.localFile.localURL}>
                      <svg>
                        <use href="#email"></use>
                      </svg>
                      <span>Email</span>
                    </a>
                    )}
                  </div>
                </div>
              </div>
            </React.Fragment>    
          </div>
        ))}
      </section>
      }
      {data.wordpressPage.acf.brand_add_collateral == null &&
        <section id="content-collateral" className="content-collateral secondaryTab"></section>
      }
      {data.wordpressPage.acf.brand_products_carried_by != null &&
      <section id="content-products" className="content-products secondaryTab">
        <div className="fCol12 brandName" css={css` color: ${data.wordpressPage.acf.brand_color}; `}>          
            <div className="subHeading">
              <h1 dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.brand_name+' Products'}} />
            </div>             
        </div>
        <div class="hide">
        {mineralized.length = 0}
        {basic.length = 0}
        {schloride.length = 0}
        {pchloride.length = 0}
        </div>
        <div className="fCol12 productLists">
        {/*data.wordpressPage.acf.brand_products_carried_by !=null && data.wordpressPage.acf.brand_products_carried_by.map((category, index) => (
          <React.Fragment>
            
              {category.prod_group_category !='' &&
              <div className="productList">
              <ExpansionPanel className="fCol12 productPanel">
                  <ExpansionPanelSummary
                    aria-controls={'product-panel'+index}
                    id={'product-panel'+index+'header'}
                  >
                    <h3 className="categoryHeader" css={css` background: ${category.prod_group_color} !important; `} dangerouslySetInnerHTML={{ __html: category.prod_group_category }}/>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                  <ul>
                    {category.prod_group_brnd !=null && category.prod_group_brnd.map(item => (
                      <React.Fragment>
                        <li css={css` border-left: 9px solid ${item.product_color}; `}>
                          <div>
                            <Link css={css` color: ${data.wordpressPage.acf.brand_color}; `} to={`/${data.wordpressPage.parent_element.parent_element.slug}/${data.wordpressPage.parent_element.slug}/${data.wordpressPage.slug}/products/${item.select_product_carried_brand.post_name}/`}>
                            <h3 dangerouslySetInnerHTML={{ __html: item.select_product_carried_brand.post_title }} />
                            </Link>
                            <div class="description">{item.brand_carried_prod_excerpt}</div>
                          </div>
                        </li>
                      </React.Fragment>
                    ))}
                  </ul>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                </div>
              }
              {category.prod_group_category =='' &&
              <div className="productList noCat">
                <ul>
                  {category.prod_group_brnd !=null && category.prod_group_brnd.map(item => (
                    <React.Fragment>
                      <li css={css` border-left: 9px solid ${item.product_color}; `}>
                        <div>
                          <Link css={css` color: ${data.wordpressPage.acf.brand_color}; `} to={`/${data.wordpressPage.parent_element.parent_element.slug}/${data.wordpressPage.parent_element.slug}/${data.wordpressPage.slug}/products/${item.select_product_carried_brand.post_name}/`}>
                          <h3 dangerouslySetInnerHTML={{ __html: item.select_product_carried_brand.post_title }} />
                          </Link>
                          <div class="description">{item.brand_carried_prod_excerpt}</div>
                        </div>
                      </li>
                    </React.Fragment>
                  ))}
                </ul>
                </div>
              }
          </React.Fragment>
        ))*/}
        <div className="hide">
          {data.allWordpressPage.edges.map(product =>(
            <React.Fragment>
              {product.node.acf.product_class.value == 'm' &&
                mineralized.push(product.node.title)
              }
              {product.node.acf.product_class.value == 'b' &&
                basic.push(product.node.title)
              }
              {product.node.acf.product_class.value == 'sc' &&
                schloride.push(product.node.title)
              }
              {product.node.acf.product_class.value == 'pc' &&
                pchloride.push(product.node.title)
              }
            </React.Fragment>
          ))}
        </div>
        {mineralized.length !=0 &&
        <div className="productList">
          <ExpansionPanel className="fCol12 productPanel" defaultExpanded={true}>
            <ExpansionPanelSummary
              aria-controls={'product-panel'}
              id={'product-panel-header'}
            >
              <h3 className="categoryHeader" css={css` background: #817a68 !important; `}>Mineralized Salt</h3>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
            <ul>
            {data.allWordpressPage.edges.map(product =>(
              <React.Fragment>
                {product.node.acf.product_class.value == 'm' &&
                <React.Fragment>
                  {product.node.wordpress_id !== 110 &&
                    
                    <li key={'productList'+product.node.id} css={css` border-left: 9px solid ${product.node.acf.product_color}; `}>
                      <Link className="productInfo" to={`/${data.wordpressPage.parent_element.parent_element.slug}/${data.wordpressPage.parent_element.slug}/${data.wordpressPage.slug}/products/${product.node.slug}/`}>
                        {product.node.acf.prod_availability != null &&
                          <div class={product.node.acf.prod_availability.prod_availability_type.value} >
                            <h3 css={css` color: ${data.wordpressPage.acf.brand_color}; `} dangerouslySetInnerHTML={{ __html: product.node.acf.product_name+'<span class="availabilityIcon"></span>' }} />
                            <div className="description" dangerouslySetInnerHTML={{ __html: product.node.acf.product_excerpt }}/>
                          </div>
                        }
                        {product.node.acf.prod_availability === null &&
                          <div>
                            <h3 css={css` color: ${data.wordpressPage.acf.brand_color}; `} dangerouslySetInnerHTML={{ __html: product.node.acf.product_name }} />
                            <div className="description" dangerouslySetInnerHTML={{ __html: product.node.acf.product_excerpt }}/>
                          </div>
                        }
                      </Link>
                      <AuthUserContext.Consumer>
                      {authUser =>
                        authUser ? (
                          <React.Fragment>
                            <Online>
                            <div id={'mainPortfolioAddportfolioPopup'+product.node.id} className="portfolioAdd" pbutton={'portfolioPopup'+product.node.id} onClick={portfolioFormOpen}>
                              <span className="icon-star-unfill">
                                <span className="path1"></span>
                                <span className="path2"></span>
                              </span>
                              <span className="icon-star-full">
                                <span className="path1"></span>
                                <span className="path2"></span>
                              </span>
                              <span className="icon-select-bars"></span>
                            </div>
                            <PortfolioProduct key={'portfolioPopup'+product.node.id} productId={'portfolioPopup'+product.node.id} productTitle={product.node.acf.product_name.replace('<span class="reg"></span>','®')} brandName={data.wordpressPage.acf.brand_name.replace('<span class="reg"></span>','®')} productLink={`/${data.wordpressPage.parent_element.parent_element.slug}/${data.wordpressPage.parent_element.slug}/${data.wordpressPage.slug}/products/${product.node.slug}`}/>
                            </Online>
                            <Offline>
                              <div className="portfolioAdd">
                                <span className="icon-star-unfill">
                                  <span className="path1"></span>
                                  <span className="path2"></span>
                                </span>
                                <span className="icon-star-full">
                                  <span className="path1"></span>
                                  <span className="path2"></span>
                                </span>
                                <span className="icon-select-bars"></span>
                              </div>
                            </Offline>
                          </React.Fragment>
                        ) : null
                    }           
                    </AuthUserContext.Consumer>
                    </li>
                    
                  }
                </React.Fragment>
                }
              </React.Fragment>
            ))}
            </ul>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
        }
        {basic.length !=0 &&
        <div className="productList">
          <ExpansionPanel className="fCol12 productPanel" defaultExpanded={true}>
            <ExpansionPanelSummary
              aria-controls={'product-panel'}
              id={'product-panel-header'}
            >
              <h3 className="categoryHeader" css={css` background: #A8B0B4 !important; `}>Basic Salt</h3>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
          <ul>
          {data.allWordpressPage.edges.map(product =>(
            <React.Fragment>
              {product.node.acf.product_class.value == 'b' &&
              <li key={'productList'+product.node.id} css={css` border-left: 9px solid ${product.node.acf.product_color}; `}>
                <Link className="productInfo" to={`/${data.wordpressPage.parent_element.parent_element.slug}/${data.wordpressPage.parent_element.slug}/${data.wordpressPage.slug}/products/${product.node.slug}/`}>
                  {product.node.acf.prod_availability != null &&
                    <div class={product.node.acf.prod_availability.prod_availability_type.value} >
                      <h3 css={css` color: ${data.wordpressPage.acf.brand_color}; `} dangerouslySetInnerHTML={{ __html: product.node.acf.product_name+'<span class="availabilityIcon"></span>' }} />
                      <div className="description" dangerouslySetInnerHTML={{ __html: product.node.acf.product_excerpt }}/>
                    </div>
                  }
                  {product.node.acf.prod_availability === null &&
                    <div>
                      <h3 css={css` color: ${data.wordpressPage.acf.brand_color}; `} dangerouslySetInnerHTML={{ __html: product.node.acf.product_name }} />
                      <div className="description" dangerouslySetInnerHTML={{ __html: product.node.acf.product_excerpt }}/>
                    </div>
                  }
                </Link>

                <AuthUserContext.Consumer>
                  {authUser =>
                    authUser ? (
                      <React.Fragment>
                        <Online>
                          <div id={'mainPortfolioAddportfolioPopup'+product.node.id} className="portfolioAdd" pbutton={'portfolioPopup'+product.node.id} onClick={portfolioFormOpen}>
                            <span className="icon-star-unfill">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </span>
                            <span className="icon-star-full">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </span>
                            <span className="icon-select-bars"></span>
                          </div>
                          <PortfolioProduct key={'portfolioPopup'+product.node.id} productId={'portfolioPopup'+product.node.id} productTitle={product.node.acf.product_name.replace('<span class="reg"></span>','®')} brandName={data.wordpressPage.acf.brand_name.replace('<span class="reg"></span>','®')} productLink={`/${data.wordpressPage.parent_element.parent_element.slug}/${data.wordpressPage.parent_element.slug}/${data.wordpressPage.slug}/products/${product.node.slug}`}/>
                        </Online>

                        <Offline>
                          <div className="portfolioAdd">
                            <span className="icon-star-unfill">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </span>
                            <span className="icon-star-full">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </span>
                            <span className="icon-select-bars"></span>
                          </div>
                        </Offline>
                    </React.Fragment>
                    ) : null
                  }           
                </AuthUserContext.Consumer>
              </li>
              }
            </React.Fragment>
          ))}
          </ul>
          </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
        }
        {schloride.length !=0 &&
        <div className="productList">
          <ExpansionPanel className="fCol12 productPanel" defaultExpanded={true}>
            <ExpansionPanelSummary
              aria-controls={'product-panel'}
              id={'product-panel-header'}
            >
              <h3 className="categoryHeader" css={css` background: #dbdddf !important; color: #092858 !important; `}>Sodium Chloride</h3>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
          <ul>
          {data.allWordpressPage.edges.map(product =>(
            <React.Fragment>
              {product.node.acf.product_class.value == 'sc' &&
              <li key={'productList'+product.node.id} css={css` border-left: 9px solid ${product.node.acf.product_color}; `}>
                <Link className="productInfo" to={`/${data.wordpressPage.parent_element.parent_element.slug}/${data.wordpressPage.parent_element.slug}/${data.wordpressPage.slug}/products/${product.node.slug}/`}>
                  {product.node.acf.prod_availability != null &&
                    <div class={product.node.acf.prod_availability.prod_availability_type.value} >
                      <h3 css={css` color: ${data.wordpressPage.acf.brand_color}; `} dangerouslySetInnerHTML={{ __html: product.node.acf.product_name+'<span class="availabilityIcon"></span>' }} />
                      <div className="description" dangerouslySetInnerHTML={{ __html: product.node.acf.product_excerpt }}/>
                    </div>
                  }
                  {product.node.acf.prod_availability === null &&
                    <div>
                      <h3 css={css` color: ${data.wordpressPage.acf.brand_color}; `} dangerouslySetInnerHTML={{ __html: product.node.acf.product_name }} />
                      <div className="description" dangerouslySetInnerHTML={{ __html: product.node.acf.product_excerpt }}/>
                    </div>
                  }
                </Link>

                <AuthUserContext.Consumer>
                  {authUser =>
                    authUser ? (
                      <React.Fragment>
                        <Online>
                          <div id={'mainPortfolioAddportfolioPopup'+product.node.id} className="portfolioAdd" pbutton={'portfolioPopup'+product.node.id} onClick={portfolioFormOpen}>
                            <span className="icon-star-unfill">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </span>
                            <span className="icon-star-full">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </span>
                            <span className="icon-select-bars"></span>
                          </div>
                          <PortfolioProduct key={'portfolioPopup'+product.node.id} productId={'portfolioPopup'+product.node.id} productTitle={product.node.acf.product_name.replace('<span class="reg"></span>','®')} brandName={data.wordpressPage.acf.brand_name.replace('<span class="reg"></span>','®')} productLink={`/${data.wordpressPage.parent_element.parent_element.slug}/${data.wordpressPage.parent_element.slug}/${data.wordpressPage.slug}/products/${product.node.slug}`}/>
                        </Online>

                        <Offline>
                          <div className="portfolioAdd">
                            <span className="icon-star-unfill">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </span>
                            <span className="icon-star-full">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </span>
                            <span className="icon-select-bars"></span>
                          </div>
                        </Offline>
                    </React.Fragment>
                    ) : null
                  }           
                </AuthUserContext.Consumer>
              </li>
              }
            </React.Fragment>
          ))}
          </ul>
          </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
        }
        {pchloride.length !=0 &&
        <div className="productList">
          <ExpansionPanel className="fCol12 productPanel" defaultExpanded={true}>
            <ExpansionPanelSummary
              aria-controls={'product-panel'}
              id={'product-panel-header'}
            >
              <h3 className="categoryHeader" css={css` background: #aebdce !important; color: #092858 !important;  `}>Potassium Chloride</h3>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
          <ul>
          {data.allWordpressPage.edges.map(product =>(
            <React.Fragment>
              {product.node.acf.product_class.value == 'pc' &&
              <li key={'productList'+product.node.id} css={css` border-left: 9px solid ${product.node.acf.product_color}; `}>
                <Link className="productInfo" to={`/${data.wordpressPage.parent_element.parent_element.slug}/${data.wordpressPage.parent_element.slug}/${data.wordpressPage.slug}/products/${product.node.slug}/`}>
                  {product.node.acf.prod_availability != null &&
                    <div class={product.node.acf.prod_availability.prod_availability_type.value} >
                      <h3 css={css` color: ${data.wordpressPage.acf.brand_color}; `} dangerouslySetInnerHTML={{ __html: product.node.acf.product_name+'<span class="availabilityIcon"></span>' }} />
                      <div className="description" dangerouslySetInnerHTML={{ __html: product.node.acf.product_excerpt }}/>
                    </div>
                  }
                  {product.node.acf.prod_availability === null &&
                    <div>
                      <h3 css={css` color: ${data.wordpressPage.acf.brand_color}; `} dangerouslySetInnerHTML={{ __html: product.node.acf.product_name }} />
                      <div className="description" dangerouslySetInnerHTML={{ __html: product.node.acf.product_excerpt }}/>
                    </div>
                  }
                </Link>

                <AuthUserContext.Consumer>
                  {authUser =>
                    authUser ? (
                      <React.Fragment>
                        <Online>
                          <div id={'mainPortfolioAddportfolioPopup'+product.node.id} className="portfolioAdd" pbutton={'portfolioPopup'+product.node.id} onClick={portfolioFormOpen}>
                            <span className="icon-star-unfill">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </span>
                            <span className="icon-star-full">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </span>
                            <span className="icon-select-bars"></span>
                          </div>
                          <PortfolioProduct key={'portfolioPopup'+product.node.id} productId={'portfolioPopup'+product.node.id} productTitle={product.node.acf.product_name.replace('<span class="reg"></span>','®')} brandName={data.wordpressPage.acf.brand_name.replace('<span class="reg"></span>','®')} productLink={`/${data.wordpressPage.parent_element.parent_element.slug}/${data.wordpressPage.parent_element.slug}/${data.wordpressPage.slug}/products/${product.node.slug}`}/>
                        </Online>

                        <Offline>
                          <div className="portfolioAdd">
                            <span className="icon-star-unfill">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </span>
                            <span className="icon-star-full">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </span>
                            <span className="icon-select-bars"></span>
                          </div>
                        </Offline>
                    </React.Fragment>
                    ) : null
                  }           
                </AuthUserContext.Consumer>
              </li>
              }
            </React.Fragment>
          ))}
          </ul>
          </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
        }
        {data.wordpressPage.acf.master_tether_selector != 'master' &&
        <div className="productList noCat">
          <ul>
          {data.allWordpressPage.edges.map(product =>(
            <React.Fragment>
              {product.node.acf.product_class.value == 'none' &&
              <li key={'productList'+product.node.id} css={css` border-left: 9px solid ${product.node.acf.product_color}; `}>
                <Link className="productInfo" to={`/${data.wordpressPage.parent_element.parent_element.slug}/${data.wordpressPage.parent_element.slug}/${data.wordpressPage.slug}/products/${product.node.slug}/`}>
                  {product.node.acf.prod_availability != null &&
                    <div class={product.node.acf.prod_availability.prod_availability_type.value} >
                      <h3 css={css` color: ${data.wordpressPage.acf.brand_color}; `} dangerouslySetInnerHTML={{ __html: product.node.acf.product_name+'<span class="availabilityIcon"></span>' }} />
                      <div className="description" dangerouslySetInnerHTML={{ __html: product.node.acf.product_excerpt }}/>
                    </div>
                  }
                  {product.node.acf.prod_availability === null &&
                    <div>
                      <h3 css={css` color: ${data.wordpressPage.acf.brand_color}; `} dangerouslySetInnerHTML={{ __html: product.node.acf.product_name }} />
                      <div className="description" dangerouslySetInnerHTML={{ __html: product.node.acf.product_excerpt }}/>
                    </div>
                  }
                </Link>
                <AuthUserContext.Consumer>
                  {authUser =>
                    authUser ? (
                      <React.Fragment>
                        <Online>
                          <div id={'mainPortfolioAddportfolioPopup'+product.node.id} className="portfolioAdd" pbutton={'portfolioPopup'+product.node.id} onClick={portfolioFormOpen}>
                            <span className="icon-star-unfill">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </span>
                            <span className="icon-star-full">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </span>
                            <span className="icon-select-bars"></span>
                          </div>
                          <PortfolioProduct key={'portfolioPopup'+product.node.id} productId={'portfolioPopup'+product.node.id} productTitle={product.node.acf.product_name.replace('<span class="reg"></span>','®')} brandName={data.wordpressPage.acf.brand_name.replace('<span class="reg"></span>','®')} productLink={`/${data.wordpressPage.parent_element.parent_element.slug}/${data.wordpressPage.parent_element.slug}/${data.wordpressPage.slug}/products/${product.node.slug}`}/>
                        </Online>
                        <Offline>
                          <div className="portfolioAdd">
                            <span className="icon-star-unfill">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </span>
                            <span className="icon-star-full">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </span>
                            <span className="icon-select-bars"></span>
                          </div>
                        </Offline>
                      </React.Fragment>
                    ) : null
                  }           
                </AuthUserContext.Consumer>
              </li>
              }
            </React.Fragment>
          ))}
          </ul>
        </div>
        }
        {data.wordpressPage.acf.master_tether_selector === 'master' &&
        <React.Fragment>
          <MasterTetherList masterID={data.wordpressPage.wordpress_id} tetherID={tetherID}/>
        </React.Fragment>
          
        }
        {/*data.wordpressPage.acf.brand_products_carried_by !=null && data.wordpressPage.acf.brand_products_carried_by.map((category, index) => (
          <React.Fragment>
            
              {category.prod_group_category !='' &&
              <div className="productList">
              <ExpansionPanel className="fCol12 productPanel">
                  <ExpansionPanelSummary
                    aria-controls={'product-panel'+index}
                    id={'product-panel'+index+'header'}
                  >
                    <h3 className="categoryHeader" css={css` background: ${category.prod_group_color} !important; `} dangerouslySetInnerHTML={{ __html: category.prod_group_category }}/>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                  <ul>
                    {category.prod_group_brnd !=null && category.prod_group_brnd.map(item => (
                      <React.Fragment>
                        <li css={css` border-left: 9px solid ${item.product_color}; `}>
                          <div>
                            <Link css={css` color: ${data.wordpressPage.acf.brand_color}; `} to={`/${data.wordpressPage.parent_element.parent_element.slug}/${data.wordpressPage.parent_element.slug}/${data.wordpressPage.slug}/products/${item.select_product_carried_brand.post_name}/`}>
                            <h3 dangerouslySetInnerHTML={{ __html: item.select_product_carried_brand.post_title }} />
                            </Link>
                            <div class="description">{item.brand_carried_prod_excerpt}</div>
                          </div>
                        </li>
                      </React.Fragment>
                    ))}
                  </ul>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                </div>
              }
              {category.prod_group_category =='' &&
              <div className="productList noCat">
                <ul>
                  {category.prod_group_brnd !=null && category.prod_group_brnd.map(item => (
                    <React.Fragment>
                      <li css={css` border-left: 9px solid ${item.product_color}; `}>
                        <div>
                          <Link css={css` color: ${data.wordpressPage.acf.brand_color}; `} to={`/${data.wordpressPage.parent_element.parent_element.slug}/${data.wordpressPage.parent_element.slug}/${data.wordpressPage.slug}/products/${item.select_product_carried_brand.post_name}/`}>
                          <h3 dangerouslySetInnerHTML={{ __html: item.select_product_carried_brand.post_title }} />
                          </Link>
                          <div class="description">{item.brand_carried_prod_excerpt}</div>
                        </div>
                      </li>
                    </React.Fragment>
                  ))}
                </ul>
                </div>
              }
          </React.Fragment>
            ))*/}
        </div>
      </section>
      }
      {data.wordpressPage.acf.brand_products_carried_by ==null &&
      <section id="content-products" className="content-products secondaryTab"></section>
      }
    </div>
    <footer className="productTabs" css={css` border-color: ${data.wordpressPage.acf.brand_color}; `}>
        <Paper square >
          <Tabs
            variant="fullWidth"
            indicatorColor="secondary"
            textColor="secondary"
            aria-label="icon label tabs example"
            className="brandTabs"
          >
            <Tab id="homeTab" className="icon-brand-home active tab" label="Brand Home" data-tab="homeTab" data-content="content-home" onClick={tabNavigation}/>
            {data.wordpressPage.acf.brand_img_galy !=null &&
              <Tab id="galleryTab" className="icon-imagery tab" label="Imagery" data-tab="galleryTab" data-content="content-gallery" onClick={tabNavigation}/>
            }
            {data.wordpressPage.acf.brand_img_galy ==null &&
               <Tab id="galleryTab" className="icon-imagery tab disabled" label="Imagery"/>
            }
            {data.wordpressPage.acf.brand_products_carried_by !=null &&
            <Tab id="productsTab" className="icon-products tab" label="Products" data-tab="productsTab" data-content="content-products" onClick={tabNavigation}/>
            }
            {data.wordpressPage.acf.brand_products_carried_by ==null &&
            <Tab id="productsTab" className="icon-products tab disabled" label="Products"/>
            }
            {data.wordpressPage.acf.brand_videos !=null &&
              <Tab id="videoTab" className="icon-videos tab" label="Videos" data-tab="videoTab" data-content="content-videos" onClick={tabNavigation}/>
            }
            {data.wordpressPage.acf.brand_videos ==null &&
              <Tab id="videoTab" className="icon-videos tab disabled" label="Videos"/>
            }
            {data.wordpressPage.acf.brand_add_collateral != null &&
              <Tab id="collateralTab" className="icon-collateral tab" label="Collateral" data-tab="collateralTab" data-content="content-collateral" onClick={tabNavigation}/>
            }
            {data.wordpressPage.acf.brand_add_collateral == null &&
              <Tab id="collateralTab" className="icon-collateral tab disabled" label="Collateral"/>
            }
            <Tab id="addTab" className="icon-additional tab" label="Additional" data-tab="addTab" data-content="additional-content" onClick={tabNavigation}/>
          </Tabs>
        </Paper>
        <div id="additional-content">
          <Tabs>
            <Tab id="galleryTab2" className="icon-imagery tab" label="Imagery" data-tab="galleryTab2" data-content="content-gallery" data-mob-tab="true" onClick={tabNavigation}/>
            {data.wordpressPage.acf.brand_videos !=null &&
              <Tab id="videoTab2" className="icon-videos tab" label="Videos" data-tab="videoTab2" data-content="content-videos" data-mob-tab="true" onClick={tabNavigation}/>
            }
            {data.wordpressPage.acf.brand_videos ==null &&
              <Tab id="videoTab2" className="icon-videos tab disabled" label="Videos"/>
            }
            {data.wordpressPage.acf.brand_add_collateral != null &&
              <Tab id="collateralTab2" className="icon-collateral tab" label="Collateral" data-tab="collateralTab2" data-content="content-collateral" data-mob-tab="true" onClick={tabNavigation}/>
            }
            {data.wordpressPage.acf.brand_add_collateral == null &&
              <Tab id="collateralTab2" className="icon-collateral tab disabled" label="Collateral"/>
            }
          </Tabs>
          <button id="addMenuClose" onClick={ closeMenu }><Icon>close</Icon></button>
        </div>
      </footer>
      <svg className="hidden">
        <defs>
          <symbol id="share-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-share"><path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path><polyline points="16 6 12 2 8 6"></polyline><line x1="12" y1="2" x2="12" y2="15"></line></symbol>
          
          <symbol id="facebook" viewBox="0 0 24 24" fill="#3b5998" stroke="#3b5998" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-facebook"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></symbol>
          
          <symbol id="twitter" viewBox="0 0 24 24" fill="#1da1f2" stroke="#1da1f2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-twitter"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></symbol>
          
          <symbol id="email" viewBox="0 0 24 24" fill="#000" stroke="#E2E4E5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></symbol>
          
          <symbol id="linkedin" viewBox="0 0 24 24" fill="#0077B5" stroke="#0077B5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-linkedin"><path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path><rect x="2" y="9" width="4" height="12"></rect><circle cx="4" cy="4" r="2"></circle></symbol>
          
          <symbol id="close" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-x-square"><rect x="3" y="3" width="25" height="25" rx="2" ry="2"></rect><line x1="9" y1="9" x2="15" y2="15"></line><line x1="15" y1="9" x2="9" y2="15"></line></symbol>
        </defs>
      </svg>
  </Layout>
  )
}
  
export default BrandTemplate
  
export const query = graphql`
  query($id: Int!) {
    site {
      siteMetadata {
        siteUrl
        title
        menuLinks {
          link
          name
        }
      }
    }
    allWordpressPage(sort: {fields: acf___product_name},filter: {wordpress_parent: {eq: $id}}) {
      edges {
        node {
          id
          title
          acf {
            product_color
            product_name
            product_excerpt
            product_class {
              label
              value
            }
            parent_brand
            prod_availability {
              prod_availability_type {
                value
              }
            }
          }
          slug
          excerpt
          wordpress_id
          
        }
      }
    }
    wordpressPage(wordpress_id: { eq: $id }) {
      title
      excerpt
      content
      slug
      wordpress_id
      parent_element {
        title
        slug
        parent_element {
          title
          slug
        }
      }
      acf {
        brand_name
        brand_color
        brand_hero {
          localFile {
            publicURL
          }
        }
        brand_logo {
          localFile {
            publicURL
          }
        }
        brand_points_rp {
          brand_point
        }
        brand_copy
        brand_site_link
        brand_site_text
        brand_footnote
        brand_family_toggle
        brand_family_img {
          localFile {
            childImageSharp {
              sizes(maxWidth:1200) {
                ...GatsbyImageSharpSizes
              }
            }
          }
        }
        parent_brand
        brand_products_carried_by {
          prod_group_category
          prod_group_color
          prod_group_brnd {
            brand_carried_prod_excerpt
            product_color
            select_product_carried_brand {
              post_title
              post_name
            }
          }
        }
        type_of_page
        master_tether_selector
        master_brand
        tether_brand
        brand_img_galy_portal
        brand_img_tab_or {
          or_check
          or_front
          or_headline
        }
        brand_img_galy {
          localFile {
            url
            absolutePath
            publicURL
          }
          caption
        }
        brand_collateral_tab_or {
          or_check
          or_front
          or_headline
        }
        brand_add_collateral {
          brand_collateral_name
          brand_collateral_name_line
          brand_collateral_order
          brand_upload_collateral {
            link
            filename
            subtype
            url {
              localFile {
                localURL
              }
              source_url
            }
          }
          brand_collateral_zip {
            filename
            url {
              localFile {
                localURL                
              }
              source_url
            }
          }
          brand_collateral_thumb {
            media_type
            localFile {
              childImageSharp {
                sizes(maxWidth:1200) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
        brand_video_tab_or {
          or_check
          or_front
          or_headline
        }
        brand_videos {
          video_description
          video_title
          video_select_path
          video_select {
            localFile {
              localURL
              url
            }
            source_url
          }
          video_poster {
            localFile {
              childImageSharp {
                sizes(maxWidth:1200) {
                  ...GatsbyImageSharpSizes
                }
              }
              url
              absolutePath
              publicURL
            }
          }
        }
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
    }
  }
`